<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">

            <a-col :md="6" :sm="24">
              <a-form-item label="手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="用户ID" prop="userNo">
                <a-input v-model="queryParam.userNo" placeholder="请输入用户ID" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="性别" prop="sex">
                <a-select placeholder="请选择性别" v-model="queryParam.sex" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.sys_user_sex" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="状态" prop="sex">
                <a-select placeholder="请选择状态" v-model="queryParam.status" style="width: 100%" allow-clear>
                  <a-select-option :value="1"> 正常  </a-select-option>
                  <a-select-option :value="0"> 禁用</a-select-option>
                  <a-select-option :value="2"> 注销</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :sexOptions="dict.type.sys_user_sex"
        :isNoticeOptions="dict.type.sys_notice_status"
        :statusOptions="dict.type.sys_enable_disable"
        @ok="getList"
      />
<!--      封禁账号-->
      <ban-user-form  ref="banUserForm" @ok="getList" />
      <update-user-no-form  ref="updateUserNoForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
       <span slot="avatar" slot-scope="text, record">
          <ImageViewer class="imageViewer"   v-if="record.avatar"  ref="previewImg"
                       v-for="(guildLogo,imgIndex) in record.avatar.split(',')"
                       :imageUrl="guildLogo"
                       preview="图片" />
        </span>

        <span slot="sex" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_user_sex']" :value="record.sex"/>
        </span>
        <span slot="isNotice" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_status']" :value="record.isNotice"/>
        </span>
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.status"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">

<!--          <a-divider type="vertical" v-hasPermi="['biz:signInRule:edit']" />-->
<!--          <a v-if="record.status==1"  v-hasPermi="['biz:signInRule:edit']" @click="handleStatus(record,0)">-->
<!--               <a-popconfirm title="确定封禁该用户吗?" @confirm="() => handleStatus(record,0)">-->
<!--                  <a-icon type="edit" /> <a>封禁</a>-->
<!--                </a-popconfirm>-->
<!--          </a>-->
              <a @click="$refs.updateUserNoForm.handleUpdate(record, undefined)" v-hasPermi="['biz:user:edit']" v-if="record.status ==1">
                <a-icon type="edit" />  修改用户ID
               </a>
            <a-divider type="vertical" v-hasPermi="['biz:signInRule:edit']" />
          <a-dropdown v-hasPermi="['system:user:resetPwd', 'system:user:edit']">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <a-icon type="double-right" />
            更多
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-hasPermi="['system:user:resetPwd']"   >
               <a @click="$refs.banUserForm.handleUpdate(record, undefined)" v-hasPermi="['biz:user:edit']" v-if="record.status ==1">
                <a-icon type="edit" />   封禁账号
               </a>
               <a v-if="record.status==0"  v-hasPermi="['biz:signInRule:edit']" @click="handleStatus(record,1)">
                       <a-icon type="edit" /> <a>解禁</a>
               </a>
                <a v-if="record.status==2"  v-hasPermi="['biz:signInRule:edit']" @click="handleStatus(record,2)">
                       <a-icon type="edit" /> <a>注销找回</a>
               </a>
            </a-menu-item>
            <a-menu-item v-hasPermi="['system:user:edit']"   >
             <a @click="handleFreeze(record,1)" v-if="record.withdrawalFreeze!=1" v-hasPermi="['system:user:edit']">
                      <a-icon type="money-collect" /> 冻结提现
             </a>
              <a @click="handleFreeze(record,0)" v-if="record.withdrawalFreeze==1" v-hasPermi="['system:user:edit']">
                      <a-icon type="money-collect" /> 解冻提现
             </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
          <!--          <a-divider type="vertical" v-hasPermi="['biz:signInRule:remove']" />-->
          <!--          <a @click="handleDelete(record)" v-hasPermi="['biz:signInRule:remove']">-->
          <!--            <a-icon type="delete" />删除-->
          <!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { pageUser,listUser, delUser,updateStatus,withdrawalFreezeUser } from '@/api/biz/user'
import CreateForm from './modules/CreateForm'
import BanUserForm from './modules/BanUserForm'
import UpdateUserNoForm from '@/views/biz/user/modules/UpdateUserNoForm.vue'
import { tableMixin } from '@/store/table-mixin'
import ImageViewer from '@/components/Viewer/ImageViewer.vue'

export default {
  name: 'User',
  components: {
    ImageViewer,
    CreateForm,BanUserForm,UpdateUserNoForm
  },
  mixins: [tableMixin],
  dicts: ['sys_user_sex', 'sys_notice_status', 'sys_enable_disable'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        email: null,
        nickname: null,
        sex: null,
        invitationCode: null,
        isNotice: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 50,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '用户ID',
          dataIndex: 'userNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '头像',
          dataIndex: 'avatar',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "avatar"}
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '邀请码',
          dataIndex: 'invitationCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'QQ绑定',
          dataIndex: 'isQqBound',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 1){
              return "已绑定"
            }else{
              return "未绑定"
            }
          }
        },
        {
          title: '微信绑定',
          dataIndex: 'isWechatBound',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 1){
              return "已绑定"
            }else{
              return "未绑定"
            }
          }
        },
        {
          title: '是否会员',
          dataIndex: 'vipFlag',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 1){
              return "是"
            }else{
              return "否"
            }
          }
        }, {
          title: '状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 1){
              return "正常"
            }else{
              return "禁用"
            }
          }
        },
        {
          title: '提现状态',
          dataIndex: 'withdrawalFreeze',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 1){
              return "冻结"
            }else{
              return "正常"
            }
          }
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,width:"160px",
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户信息列表 */
    getList () {
      this.loading = true
     pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        mobile: undefined,
        email: undefined,
        nickname: undefined,
        sex: undefined,
        invitationCode: undefined,
        isNotice: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 封禁，解禁，账号找回按钮操作 */
    handleStatus (row,opType) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      let text="";
      if(opType==0){
        text="封禁";
      }
      if(opType==1){
        text="解禁"
      }
      if(opType==2){
        text="找回";
      }
      this.$confirm({
        title: '确认'+text+'该用户吗?',
        content: '当前选中用户为 ' + row.nickname + ' 的数据',
        onOk () {
          let data ={"id":ids,"status":opType}
          return updateStatus(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 冻结提现按钮操作 */
    handleFreeze (row,type) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      let text="";
      if(type==1){
        text="冻结提现";
      }
      if(type==0){
        text="解冻提现"
      }
      this.$confirm({
        title: '确认 '+text+' 所选中数据?',
        content: '当前选中用户为 ' + row.nickname+ ' 的数据',
        onOk () {
          let data = {"id":row.id}
          return withdrawalFreezeUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '操作成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中用户为 ' + ids + ' 的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/user/export', {
            ...that.queryParam
          }, `用户信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style lang="less" scoped>
//图片样式
.imageViewer{
  width: 48px;height:48px;display: flex;justify-content: center;margin: auto;
}
</style>
